<!--:totalRecords="totalRecords"
:lazy="true"
-->
<!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
<template>
  <DataTable
    :value="listDataService"
    :lazy="true"
    class=""
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="scrollHeight !== '' ? true : false"
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :currentPageReportTemplate="
      $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM + ' {totalRecords}'
    "
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="c.class ? 'txt-' + c.class : ''"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="c.class ? 'txt-' + c.class : ''"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="c.style ? c.style : ''"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="c.style ? c.style : ''"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar
              v-if="c.custom == 'image'"
              :image="getData(c, slotProps.data)"
              size="xlarge"
            />
            <div
              v-if="c.custom == 'SelectionText'"
              v-html="callbackDataSelect(c, slotProps.data)"
            ></div>
          </div>
          <span
            v-else
            v-html="getData(c, slotProps.data)"
            :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"
          ></span>
        </div>
      </template>
      <div>{{ c }}</div>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event, c.filter.type, c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event, c.filter.type, c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="
                  getCustomClass(
                    c,
                    slotProps.option[c.filter.optionKey],
                    slotProps.data
                  )
                "
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="yy-mm-dd"
          id="date_from"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event, getField(c))"
          :manualInput="false"
        />
      </template>
    </Column>

    <template #empty>
      {{ $t("datatable.empty") }}
    </template>
    <template #loading>
      {{ $t("datatable.loading") }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
//import gql from "graphql-tag";
//import DataServices from "@/core/DataServices";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
    obj_id: String,
  },
  data() {
    return {
      //filters: {},
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows:
        this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: "", // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService: null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction: null,
      firstRecordIndex: 0,
    };
  },
  async created() {
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:", this.model);
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
  },
  mounted() {
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      // filters: this.filters,
      //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)) {
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)) {
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = localStorage.getItem(this.page_transaction)
          ? JSON.parse(localStorage.getItem(this.page_transaction))
          : {};
        console.log(
          "this.lazyParamsthis.lazyParams:" + this.page_transaction,
          this.lazyParams
        );
        this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222", this.lazyParams);
        /* if (this.lazyParams){
                            console.log("this.lazyParams.filters",this.lazyParams.filters);

                          }*/
      }
    }
    this.loading = false;
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {
    // togglePopup(id) {
    //   console.log("togglePopuptogglePopuptogglePopuptogglePopup", id);
    // },
    callbackDataSelect(c, data) {
      var text = "";
      switch (c.key) {
        case "code":
          if (data.customer.code !== undefined && data.customer.code !== null) {
            text = data.customer.code;
          } else {
            text = "";
          }
          break;
        case "name":
          if (data.customer.name !== undefined && data.customer.name !== null) {
            text = data.customer.name;
          } else {
            text = "";
          }
          break;
        case "company":
          if (
            data.customer.company.code !== undefined &&
            data.customer.company.code !== null
          ) {
            text = data.customer.company.code;
          } else {
            text = "";
          }
          break;
        default:
      }
      return text;
    },
    select_date(event, col_name) {
      if (
        this.filters[col_name][0] !== null &&
        this.filters[col_name][1] !== null
      ) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name,
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if (col.inputOptions?.mode == "icon") {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange) f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image")
        style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      console.log("colcolcol:", col);
      console.log("rowrowrowrow:", row);
      var value = "";
      if (col.type == "Date" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("HH:mm:ss DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment
          .tz(row[col.key], "Asia/Ho_Chi_Minh")
          .format("HH:mm - DD/MM/yyyy");
      } else if (col.type == "Hour" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (
          col.models !== undefined &&
          this.options[col.key] !== undefined
        ) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter((a) => a.value == row[col.key]);
          if (
            objResult !== undefined &&
            Object.keys(objResult) &&
            Object.entries(objResult).length
          ) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result =
            row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style)
        value = '<abbr style="' + col.style + '">' + value + "<abbr/>";
      else if (col.options) {
        var filtered = col.options.filter((a) => a.value === row[col.key]);
        if (
          Object.keys(filtered) &&
          Object.entries(filtered).length &&
          filtered[0].style
        ) {
          value =
            '<abbr style="' + filtered[0].style + '">' + value + "<abbr/>";
        }
      }
      return value;
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      return this.$CoreService.getFilterCommonPlaceHolder(col);
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams: this.lazyParams,
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit";
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit";
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
      });
    },
    async dataList(lazyParams) {
      console.log("lazyParams");
      console.log("lazyParams:", lazyParams);
     /* var offset = lazyParams.first;
      // var limit = lazyParams.rows;
      console.log("this.model.tableName:", this.model.tableName);
      var gqLQueryList = DataServices.getListFreightCustomer("freightcustomer_");
      var where = { freight_id: { _eq: this.obj_id },deleted: {_eq: false},customer_id: {_is_null: false} };
      var filters = lazyParams.filters;
      if (filters) {
        for (const [key, value] of Object.entries(filters)) {
          if (!this.$commonFuction.isEmpty(value.value)) {
            if (value.type_input == "input") {
              if (key == "fullname") {
                where["plain_fullname"] = {
                  _like: "%" + this.$commonFuction.SkipVN(value.value) + "%",
                };
              } else {
                where[key] = { _like: "%" + value.value + "%" };
              }
            } else if (value.type_input == "select") {
              if (key == "country_id") {
                where["country_id"] = { _eq: value.value };
              } else if (key == "state_id") {
                where["state_id"] = { _eq: value.value };
              } else if (key == "district_id") {
                where["district_id"] = { _eq: value.value };
              } else if (key == "ward_id") {
                where["ward_id"] = { _eq: value.value };
              } else if (key == "active") {
                where["active"] = { _eq: value.value };
              }
            }
          }
        }
      }
      var resData = await this.$apollo.mutate({
        mutation: gqLQueryList,
        variables: {
          where_key: where,
          offset: offset,
          // limit: limit,
          // orderBy: { name: "desc" },
        },
      });
      var listData = resData.data[this.model.tableName];

      var gqLQueryCount = DataServices.countDataFreightCustomer("freightcustomer_");
      var countRes = await this.$apollo.mutate({
        mutation: gqLQueryCount,
        variables: {
          where_key: where,
        },
      });
      var total =
        countRes.data[this.model.tableName + "_aggregate"]["aggregate"][
          "count"
        ];
*/
      var dataRes = {
        listData: this.tableData,
        total: this.tableData.length,
      };
      console.log("dataRes:", dataRes);
      return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log("this.lazyParams:", this.lazyParams);
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then((data) => {
        console.log("datadata:", data);
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          if (
            data.listData[i].rel_object_group_uses !== undefined &&
            data.listData[i].rel_object_group_uses !== null
          ) {
            data.listData[i].name_group_filter =
              data.listData[i].rel_object_group_uses.group_id;
          } else {
            data.listData[i].name_group_filter = 0;
          }
        }
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        that.loading = false;
      });
    },
    onPage(event) {
      console.log("eventeventeventevent", event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    onFilter(event, type_input, key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log("this.test", this.test);
      console.log("this.test", this.tableData);
      console.log("tableData", this.whereKeyList);
      console.log("filters:", this.filters);
      this.filtersCustom[key] = {
        type_input: type_input,
        value: this.filters[key],
      };
      console.log("this.filtersCustom:", this.filtersCustom);
      this.lazyParams.first = 0;
      this.onLazyEvent();
      /*setTimeout(() => {
                          this.customers = this.datasource.slice(event.first, event.first + event.rows);
                          this.loading = false;
                        }, 500);*/
    },
  },
};
</script>
<style lang="scss">
.p-paginator-bottom {
  padding: 0 !important;
}
.icon-check {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  color: #00973d;
}
.icon-times {
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  color: #f5564e;
}
</style>
