<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
      <div>
    <div class="responsive-container w-100">
      <div class="responsive-filter-label">Mã chuyển giao</div>
      <InputText
          type="text"
          v-model="filters[$CommonList.getFieldFilter(responsiveFilter)]"
          class="text-box mb-3"
          placeholder="Nhập"
          @keyup="onFilter($event, responsiveFilter.filter.type, responsiveFilter.key)"
        />
    </div>
    <div
      class="responsive-container me-3 container-flex-end"
      v-if="actions.header"
    >
      <span class="responsive-action-label">Thêm mới</span>
      <Button
        type="button"
        :icon="getIcon(actions.header.icon)"
        :class="getClass(actions.header.name)"
        v-on:click="
          actionsClicked(actions.header.name, actions.header, slotProps)
        "
      >
      </Button>
    </div>
  <DataTable
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >
    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <!--{{c}}-->
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div class="text"
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
            <div @click.capture="click_tag_a(c ,slotProps.data)" class="click_tag_a" v-if="c.custom == 'CutomCLickA'"  >{{getDataClickA(c ,slotProps.data)}}</div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="yy-mm-dd"
          id="date_from"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />
      </template>
    </Column>
    <Column
      v-if="actions"
      headerStyle="width: 10em; text-align: center"
      bodyStyle="width: 10em; text-align: center"
    >
      <template #body="slotProps">
        <span class="responsive-label">Hành động</span>
        {{ slotProps.data.color }}
        <div class="txt-center">
          <Button
          v-for="(a, k) in actions.rows(slotProps.data)"
          :key="k"
          type="button"
          :icon="getIcon(a.icon)"
          :class="getClass(a.name)"
          :disabled="getDisabled(a.disabled)"
          style="margin: 0px 0px 4px 15px"
          v-on:click="actionsClicked(k, a, slotProps)"
          ></Button>
        </div>
      </template>
      <template #header="slotProps">
        <Button
          v-if="actions.header"
          type="button"
          :icon="getIcon(actions.header.icon)"
          :class="getClass(actions.header.name)"
          v-on:click="actionsClicked(actions.header.name, actions.header, slotProps)"
        ></Button>
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
        <Dialog
            :key="keyPopup"
            header="Danh sách kiện hàng"
            :visible.sync="showPackageDialog"
            :contentStyle="{ overflow: 'visible' }"
            :modal="true"
        >
          <LIST_PACKAGE
              v-if="packageModel"
              :key="packageKey"
              :tableData="packagelistData"
              :model="packageModel"
              :options="options"
          ></LIST_PACKAGE>
          <template #footer>
            <Button
                label="Đóng"
                @click="showPackageDialog = false"
                icon="pi pi-check"
                class="p-button-success main-style-button"
            />
          </template>
        </Dialog>
  </div>

</template>

<script>
import moment from "moment-timezone";
import LIST_PACKAGE from "./list-package";
//import gql from "graphql-tag";
import DataServices from "@/core/DataServices";
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
  props: {
    model: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  components: {
    LIST_PACKAGE,
    // Popup
  },
  data() {
    return {
      //filters: {},
      packagelistData:[],
      packageKey:0,
      packageModel:null,
      showPackageDialog:false,
      keyPopup: 0,
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0,
      responsiveFilter: null,
      role: {
        view: false,
        add: false,
        edit: false,
        delete: false,
      }
    };
  },
  async created() {
    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:",this.model)
    console.log(this.tableData);
    this.test = this.tableData;
    //this.listDataService = await this.dataList();
  },
  async mounted () {
    var arr_role = await this.$CoreService.checkPermission(this.$constants.KEY_MENU.LIST_ORDER_SHIPPING);
    this.role.add = (arr_role.indexOf(this.$constants.ROLE.ADD) < 0) ? false:true;
    this.role.edit = (arr_role.indexOf(this.$constants.ROLE.EDIT) < 0) ? false:true;
    this.role.delete = (arr_role.indexOf(this.$constants.ROLE.DELETE) < 0) ? false:true;
    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    console.log("this.$route.query.page_transaction:",this.$route.query.page_transaction)
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        this.callbackFilter()
        window.history.pushState({"html":"","pageTitle":""},"", '/admin/list-order_shipping');
        //this.lazyParams.filters = obj_filter;
        //console.log("this.lazyParams:22222222",this.lazyParams);
       // console.log("this.obj_filter:22222222",obj_filter);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }
    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
    this.getResponsiveFilter();
  },
  methods: {
    callbackFilter(){
      var list_filter =  this.lazyParams.list_filter
      //var obj_filter = {};
      for (let i = 0; i < list_filter.length; i++) {
        var fil = list_filter[i]
        for (let key in fil) {
          var item_data = fil[key]
          this.filtersCustom[key] = fil[key]
          if (item_data.type_input == 'DateRange'){
            var array_data_tmp = [new Date(item_data?.value?.from),new Date(item_data?.value?.to)];
            this.$set(this.filters, key,array_data_tmp );
          }else {
            this.$set(this.filters, key,fil[key].value );
          }
        }
      }
    },
    getDataClickA(c, data) {
      var text = "";
      switch(c.key) {
          // key la cai name
        case 'total_order':
          text = data.total_order;
          break;
        case 'total_package':
          text = data.total_package;
          break;
        default:
      }
      return text;
    },
    getResponsiveFilter(){
      var displayFields = this.model.getDisplayFields();
      var tempFilter = [];
      for (var i = 0; i < displayFields.length; i++) {
        if (displayFields[i].key == "code") {
          tempFilter.push(displayFields[i]);
        }
      }
      this.responsiveFilter = tempFilter[0];
      // console.log("SHIBABA", this.responsiveFilter);
      // console.log("SHIBABABA", this.responsiveFilter.filter.type);
    },
    async click_tag_a(c ,data){
      switch(c.key) {
          // key la cai name
        case 'total_order':
          var pageTransaction = (new Date()).getTime();

          var filter = this.lazyParams.filters
          var filter_root = this.filters ?? []
          console.log("filter_rootfilter_root11:",filter_root)
          var list_filter = []
          for (let key in filter) {
            //if (filter.hasOwnProperty(key)) { // Ensure the property belongs to the object itself, not inherited
            console.log(`wwwwwww ${key}: ${filter[key]}`);
            //}
            var itemdata={};
            itemdata[key] = filter[key];
            list_filter.push(itemdata)
          }
          this.lazyParams.list_filter = list_filter;
          this.lazyParams.filter_root = filter_root;
          console.log("payload.filterfilter",filter);
          console.log("payload.lazxsdsss",JSON.stringify(this.lazyParams));
          localStorage.setItem(pageTransaction.toString(), JSON.stringify(this.lazyParams));
          if(this.role.edit){
            this.$router.push({ path: '/admin/list-order_shipping/edit/'+data.id ,query: { key_tmp: (new Date()).getTime(),page_transaction:pageTransaction } })
          }else {
            this.$router.push({ path: '/admin/list-order_shipping/view/'+data.id ,query: { key_tmp: (new Date()).getTime(),page_transaction:pageTransaction } })
          }
          break;
        case 'total_package':
          var gqLQueryList = DataServices.getList("order_shipping_package");
          var where = {order_shipping_id:{_eq:data.id}};
          var resData = await this.$apollo.mutate({
            mutation: gqLQueryList,
            variables: {
              where_key:where ,
              /*offset:offset,
              limit:limit,*/
              //orderBy:this.orderBy
            }
          });
          var listData = resData.data['order_shipping_item'];
          var list_data_convert = [];
          var map_package = {};
          var map_order_group_parcel_id = {}
            console.log("listDatalistData:",listData)
          if(listData){
            for (let i = 0; i < listData.length; i++) {
                var item_data_ = listData[i]
                var order_group_parcel_id = item_data_?.order_group_parcel_id ?? null;
                if (order_group_parcel_id){
                  if (this.$commonFuction.isEmpty(map_order_group_parcel_id[order_group_parcel_id])) {
                    map_order_group_parcel_id[order_group_parcel_id] = item_data_;
                    var order_group_parcel = item_data_?.order_group_parcel?.order_parcels ?? [];
                    console.log("order_group_parcel:",order_group_parcel)
                    for (let j = 0; j < order_group_parcel.length; j++) {
                        var item_parcel = order_group_parcel[j];
                      console.log("item_parcelitem_parcel:",item_parcel)
                        var parcel_id = item_parcel?.parcel?.id ?? null;
                        var parcel_name = item_parcel?.parcel?.name ?? null;
                        var parcel_number = item_parcel?.number ?? 0;
                        if (parcel_id){
                          if (this.$commonFuction.isEmpty(map_package[parcel_id])) {
                            list_data_convert.push({
                              id:parcel_id,
                              text_name:parcel_name,
                              text_count:0
                            })
                            map_package[parcel_id] = 0;
                          }
                          map_package[parcel_id] = map_package[parcel_id]+parcel_number;
                        }
                    }
                  }
                }
            }
          }
          for (let k = 0; k < list_data_convert.length; k++) {
            var parcel_id_key = list_data_convert[k].id
            list_data_convert[k].text_count = map_package[parcel_id_key]
          }
          console.log("list_data_convert:",list_data_convert)

          this.packageModel = DataServices.getModel("order_shipping_package");
          this.packagelistData = list_data_convert
          this.packageKey++;
          this.showPackageDialog = true;
          this.keyPopup++;
          break;
        default:
      }


    },
    callbackDataSelect(c,data){
      console.log("sasasawqwqfdf")
      var text = "";
      switch(c.key) {
        // key la cai name
        case 'active':
          if(data.active == true){
            text = `<i style="color: green" class="pi pi-check icon"></i>`;
          }else{
            text = `<i style="color: red" class="pi pi-times icon"></i>`;
          }
          break;
        case 'updated_by_id':
          if (data.updated_by_id){
            text = data.updated_by_id[0] ? data.updated_by_id[0].fullname : "-";
          }
          break;
        /*case 'vehicle_id':
            text = data.country.name ? data.country.name : "-";
          break;*/
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      console.log("colcolcol:",col);
      console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm:ss DD/MM/yyyy");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn ${this.getLabel(col)}`;
      } else if (col.filter.type == "input") {
        return `Nhập`;
      } else {
        return `Lọc`;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      //console.log("this.lazyParams2333",this.$commonFuction.convertJsonObject(this.lazyParams))
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
     var gqLQueryList = DataServices.getList(this.model.tableName);
     console.log("ggLQueryList")
     console.log(gqLQueryList)
     var where = {deleted: {_eq: false}};
     var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
      where.order_shipping_items = {order: {company_id: {_in: listDisplayCompany}}};
    }
     var filters = lazyParams.filters;
     console.log('filters')
     console.log(filters)
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
              if (key == 'code'){
                where.code = { 
                  _iregex: this.$commonFuction.search(value.value),
                };
              }
             else if (key == "vehicle_id_key") {
               where.vehicle_delivery_id = { name: {
                   _iregex: this.$commonFuction.search(value.value),
                 }
               };
             }
              else if (key == "uid_key") {
                where.user = {  fullname: {
                    _iregex: this.$commonFuction.search(value.value),
                  }
                };
              }
             else {
               where[key] = {_like: "%" + value.value + "%"};
             }
           }
           else if (value.type_input == 'select') {

             if (key == 'country_id') {
               where['country_id'] = {_eq: value.value};
             }

             if (key == 'state_id') {
               where['state_id'] = {_eq: value.value};
             }

             if (key == 'district_id') {
               where['district_id'] = {_eq: value.value};
             }

             if (key == 'type_id') {
               where['type_id'] = {_eq: value.value};
             }

             console.log("valuueeeee",value.value)
             if (key == 'active') {
               where['active'] = {_eq: value.value};
             }

           }

           // else if (value.type_input == 'select') {
           //   if (key == 'company_id') {
           //     console.log("valuueeeeecompany_id",value.value)
           //     where['company_id'] = {_eq: value.value};
           //   }
           //   console.log("valuueeeeecompany_id",value.value)
           // }
         }
       }
     }
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{updated_at: 'desc'}
       }
     });
     var listData = resData.data[this.model.tableName];

     var gqLQueryCount = DataServices.countData(this.model.tableName);
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data[this.model.tableName+'_aggregate']['aggregate']['count'];

     var dataRes = {
       listData: listData,
       total: total,
     };
     console.log('dataRes:',dataRes);
     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(data => {
        console.log("datadata:", data)
        //var count = 0;
        if (data.listData.length < 1) {
          that.listDataService = data.listData;
          that.totalRecords = data.total;
          that.loading = false;
        }
        var stt = this.lazyParams.first;
        for (var i = 0; i < data.listData.length; i++) {
          stt++;
          data.listData[i].stt = stt;
          if(data.listData[i].rel_object_group_uses !== undefined && data.listData[i].rel_object_group_uses !== null){
              data.listData[i].name_group_filter = data.listData[i].rel_object_group_uses.group_id;
          }else{
            data.listData[i].name_group_filter = 0;
          }
        }
        that.listDataService = data.listData;
        that.totalRecords = data.total;
        this.model.processData(data.listData)
        that.loading = false;
        this.countDropdown++;
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      this.filtersCustom[key] = {
        type_input:type_input,
        value:this.filters[key],
      };
      console.log('this.filtersCustom:',this.filtersCustom);
      this.lazyParams.first = 0;
      this.onLazyEvent()
      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
  }
};
</script>
<style lang="scss">
@media (min-width: 1000px) {
  .p-paginator-bottom {
    padding: 0 !important;
  }
  .icon {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .click_tag_a {
    text-align: center;
    text-decoration: underline;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #C40380;
    cursor: pointer;
  }
  .container-flex-end {
    margin-bottom: 32px;
  }
  .txt-center {
    max-width: 200px !important;
  }
}


@media (max-width: 600px){
  .container-flex-end{
    display: flex !important;
    justify-content: flex-end !important;
  }
  .text{
    text-align: right!important;
  }
  .p-inputtext{
    border: 1px solid #D0D4D9;
  }
  .p-selectable-row{
    border: none!important;
  }

}



// -----------------------------

@media (max-width: 960px) {
  .container-flex-end {
    display: flex !important;
    justify-content: flex-end !important;
  }
  .text {
    text-align: right !important;
  }
  .p-selectable-row{
    border: none!important;
  }
}





</style>